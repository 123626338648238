import Land from '../services/land';
import { isSatellite } from './map';

const geostyleMonitoring = mdd => {
  /**
   * fillColor:
   *    if monitoringDueDate < today: #ff5252
   *    if monitoringDueDate >= today && < 90 days: #f9a825
   *    if monitoringDueDate > 90 days: #4caf50
   */

  // fillColor: '',
  return {
    textColor: Land.monitoringColor({ date1: mdd, date2: new Date() })
  };
};

const geostyleHighlightPreserve = {
  fillColor: '#E1BEE7',
  lineColor: '#E1BEE7',
  lineWidth: 3
};

const geostyleLandFs = {
  lineColor: '#000000',
  fillColor: '#000000'
};

const geostyleLandFsSatellite = {
  lineColor: '#ffffff',
  fillColor: '#ffffff',
  lineWidth: 3
};

const geostyleLandCr = {
  fillColor: '#76448A',
  lineColor: '#76448A',
  fillOpacity: 0.02,
  fillOpacityHover: 0.06,
  lineWidth: 2
};

const geostyleLandCrSatellite = {
  lineColor: '#D7BDE2',
  fillColor: '#D7BDE2',
  lineWidth: 3
};

const geostyleLandOt = {
  lineColor: '#B03A2E',
  fillColor: '#B03A2E',
  fillOpacity: 0.02,
  fillOpacityHover: 0.06,
  lineOpacity: 0.8,
  lineWidth: 3
};

const geostyleLandOtSatellite = {
  lineColor: '#F5B7B1',
  fillColor: '#F5B7B1',
  lineOpacity: 0.8,
  lineWidth: 3
};

const geostyleTct = {
  lineColor: '#00BCD4',
  fillColor: '#00BCD4',
  textColor: '#00BCD4'
};

const geostyleTot = {
  fillColor: '#FFEB3B',
  lineColor: '#FFEB3B',
  textColor: '#FFEB3B'
};

const geostyleParcelPoint = {
  circleRadius: 5,
  fillOpacity: 1,
  lineOpacity: 1,
  fillColor: '#e55e5e',
  lineColor: '#e55e5e'
};

const geostyleParcelSatellite = {
  fillColor: '#FFFFFF',
  textColor: '#FFFFFF',
  lineColor: '#FFFFFF',
  fillOpacitySatellite: 0,
  fillOpacity: 0,
  fillOpacitySatelliteHover: 0.1
};

const geostyleParcel = {
  fillColor: '',
  textColor: '',
  fillOpacity: 0.05,
  fillOpacitySatellite: 0,
  fillOpacitySatelliteHover: 0.1,
  fillOpacityHover: 0.2,
  lineWidth: 1,
  lineOpacity: 1
};

const getLandGeostyle = (land, mapStyle) => {
  const landType = Land.landTypes().filter(l => {
    land = String(land).toLowerCase();
    return (
      l.text.toLowerCase() === land ||
      l.value.toLowerCase() === land ||
      l.shortName.toLowerCase() === land
    );
  });

  const landShortName = landType[0]?.shortName;

  const satellite = isSatellite(mapStyle);

  if (landShortName === 'FS') {
    if (satellite) {
      return geostyleLandFsSatellite;
    } else {
      return geostyleLandFs;
    }
  } else if (landShortName === 'CR') {
    if (satellite) {
      return geostyleLandCrSatellite;
    } else {
      return geostyleLandCr;
    }
  } else if (landShortName === 'OT') {
    if (satellite) {
      return geostyleLandOtSatellite;
    } else {
      return geostyleLandOt;
    }
  }

  return {};
};

const geostyleParcelSearchHighlight = {
  geometry: 'polygon',
  fillColor: '#FCE4EC',
  lineColor: '#E91E63',
  fillOpacity: 0.1,
  lineOpacity: '0.4',
  lineWidth: '5'
};

const geostylePhotoStation = {
  geometry: 'polygon',
  fillColor: 'orange',
  lineColor: 'orange',
  fillOpacity: 0.05,
  fillOpacityHover: 0.5,
  fillOpacitySatellite: 0.05,
  fillOpacitySatelliteHover: 0.5,
  lineOpacity: '1',
  lineWidth: '2'
};

const geostylePhotostationConfirmed = {
  geometry: 'polygon',
  fillColor: '#9dc4eb',
  lineColor: '#9dc4eb',
  fillOpacity: '0',
  fillOpacityHover: '0.3',
  lineOpacity: '0.4',
  lineWidth: '3'
};

const geostylePhotoStationUpdate = {
  geometry: 'polygon',
  fillColor: '#1976d2',
  lineColor: '#1976d2',
  fillOpacity: '0',
  fillOpacityHover: '0.5',
  lineOpacity: '1',
  lineWidth: '3'
};

export {
  geostyleMonitoring,
  geostyleHighlightPreserve as geostyleHighlight,
  geostyleTct,
  geostyleTot,
  geostyleParcelSatellite,
  geostyleParcel,
  geostyleLandFs,
  geostyleLandFsSatellite,
  geostyleLandCr,
  geostyleLandCrSatellite,
  geostyleLandOt,
  geostyleLandOtSatellite,
  getLandGeostyle,
  geostyleParcelSearchHighlight,
  geostylePhotoStation,
  geostylePhotoStationUpdate,
  geostyleParcelPoint,
  geostylePhotostationConfirmed
};
