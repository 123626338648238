import { getFirestore, timestamp } from './firebase';
import config from '../config';
import { isSatellite } from '../utils/map';
import { dateDiff, sort } from '../utils';
import moment from 'moment';
import Organization from './organization';
import {useDocumentHistory} from '../services/documentHistory'
    const {addDocHistory} = useDocumentHistory()

const geoStyle = {
  fillColor: '',
  lineColor: '',
  fillOpacity: '',
  fillOpacityHover: '',
  lineOpacity: '',
  lineWidth: ''
};

const MONITORING_COLORS = [
  '#ff5252', //late
  '#f9a825', //now
  '#4caf50'
];

const STYLE = {
  fs: {
    default: {
      fillColor: '',
      lineColor: '#000000'
    },
    satellite: {
      fillColor: '',
      lineColor: '#ffffff',
      lineWidth: 3
    }
  },
  cr: {
    default: {
      fillColor: '',
      lineColor: '#76448A',
      fillOpacity: 0.02,
      fillOpacityHover: 0.06,
      lineOpacity: null,
      lineWidth: 2
    },
    satellite: {
      fill: '',
      lineColor: '#D7BDE2',
      fillOpacity: null,
      fillOpacityHover: null,
      lineOpacity: null,
      lineWidth: 3
    }
  },
  ot: {
    default: {
      fill: '',
      lineColor: '#B03A2E',
      fillOpacity: 0.02,
      fillOpacityHover: 0.06,
      lineOpacity: 0.8,
      lineWidth: 3
    },
    satellite: {
      fill: '',
      lineColor: '#F5B7B1',
      fillOpacity: null,
      fillOpacityHover: null,
      lineOpacity: 0.8,
      lineWidth: 3
    }
  }
};

const ISSUE_STATES = {
  ISSUE_EXISTS: 'ISSUE_EXISTS',
  ISSUE_RESOLVED: 'ISSUE_RESOLVED'
};

const LAND_TYPES = [
  {
    text: 'Fee Simple',
    value: 'feeSimple',
    shortName: 'FS',
    style: { color: STYLE.fs.default.lineColor, dark: true }
  },
  {
    text: 'CR',
    value: 'cr',
    shortName: 'CR',
    style: { color: STYLE.cr.default.lineColor, dark: true }
  },
  {
    text: 'Other',
    value: 'other',
    shortName: 'OT',
    style: { color: STYLE.ot.default.lineColor, dark: true }
  }
];

const LAND_STATUS = [
  { text: 'Prospect', value: 'prospect' },
  { text: 'Closed', value: 'closed' }
];

const LAND_PRESERVES = [
  { text: 'Village Pond', value: 'villagePond' },
  { text: 'Edgewood', value: 'edgewood' },
  { text: 'Blank', value: 'blank' }
];

const ACTIVITY_TYPES = [
  { text: 'Monitoring', value: 'monitoring' },
  { text: 'Issues', value: 'issues' },
  { text: 'Improvement', value: 'improvement' },
  { text: 'Trails', value: 'trails' },
  { text: 'Acquisition', value: 'acquisition' },
  { text: 'Other', value: 'other' }
];

const BINDER = [
  { text: 'Complete', value: 'complete' },
  { text: 'partial', value: 'partial' },
  { text: 'Missing', value: 'missing' }
];

class Land {
  constructor() {
    this.ref = getFirestore().collection(config.firestore.land);
    this.activityRef = getFirestore().collection(config.firestore.landActivity);

    this.LAND_TYPES = LAND_TYPES;
    this.LAND_STATUS = LAND_STATUS;
    this.LAND_PRESERVES = LAND_PRESERVES;
    this.ACTIVITY_TYPES = ACTIVITY_TYPES;
    this.STYLE = STYLE;
    this.BINDER = BINDER;
    this.MONITORING_COLORS = MONITORING_COLORS;
    this.landId = null;
    this.activities = null;
  }
  detail(id) {
    return new Promise((resolve, reject) => {
      this.ref
        .doc(id)
        .get()
        .then(res => {
          if (res.exists) {
            resolve({ ...res.data() });
          } else {
            reject({ message: 'land document does not exists', code: 404 });
          }
        })
        .catch(reject);
    });
  }
  save(parcelId, data) {
    return new Promise((resolve, reject) => {
      const detail = { ...timestamp(), ...data, ...timestamp(false, true) };
      this.ref
        .doc(parcelId)
        .set(detail)
        .then(()=>{
          resolve()
          addDocHistory({ id: parcelId,
            data,
            docCollection: this.ref.id
          })
        })
        .catch(reject);
    });
  }
  addActivity(landId, data) {
    return new Promise((resolve, reject) => {
      if (!landId) {
        reject({ message: 'Land ID is required' });
      }
      const detail = { ...data, ...timestamp(), landId };
      this.activityRef
        .add(detail)
        .then(()=>{
          resolve()
          addDocHistory({ id: landId,
            data,
            docCollection: this.activityRef.id
          })
        })
        .catch(reject);
    });
  }
  getActivity(id) {
    return new Promise((resolve, reject) => {
      this.activityRef
        .doc(id)
        .get()
        .then(res => {
          if (res.exists) {
            resolve(res.data());
          } else {
            reject({ message: 'Activity does not exists' });
          }
        })
        .catch(reject);
    });
  }
  listActivity(landId, type, cb) {
    this.landId = landId;
    return new Promise((resolve, reject) => {
      let q = this.activityRef.where('landId', '==', landId);
      q = q.orderBy('createdAt', 'desc');
      if (type) {
        q = q.where('type', '==', type);
      }
      if (cb) {
        resolve(
          q.onSnapshot(res => {
            const data = [];
            if (res.docs.length) {
              res.forEach(item => {
                data.push({ id: item.id, ...item.data() });
              });
            }
            this.activities = data;
            cb(data);
          })
        );
      } else {
        q.get()
          .then(res => {
            const data = [];
            if (res.docs.length) {
              res.forEach(item => {
                data.push({ id: item.id, ...item.data() });
              });
            }
            this.activities = data;
            resolve(data);
          })
          .catch(reject);
      }
    });
  }
  deleteActivity(id) {
    return this.activityRef.doc(id).delete();
  }
  updateActivity(activityId, data) {
    const detail = { ...data, ...timestamp(false, true) };
    return this.activityRef.doc(activityId).set(detail).then(()=>{
      addDocHistory({
        id: data?.landId || activityId,
        data,
        docCollection: this.activityRef.id
      })
    })
  }

  static landType(type) {
    return LAND_TYPES.filter(t => t.value === type)[0];
  }

  static landTypes() {
    return LAND_TYPES;
  }

  /**
   *
   * @param {object} parcel
   * @returns {('feeSimple' | 'cr' | 'other')}
   */
  static parcelLandType(parcel) {
    return parcel?.metrics?.land?.information?.type;
  }

  /**
   *
   * @param {('feeSimple' | 'cr' | 'other')} type
   * @param {string} mapStyle
   * @returns {geoStyle}
   */
  static getStyle(type, mapStyle) {
    if (!type) {
      return;
    }
    const shortName = LAND_TYPES.filter(
      land =>
        land.shortName.toLowerCase() === type.toLowerCase() ||
        land.value.toLocaleLowerCase() === type.toLowerCase()
    )[0].shortName.toLowerCase();
    if (isSatellite(mapStyle)) {
      return STYLE[shortName].satellite;
    } else {
      return STYLE[shortName].default;
    }
  }

  static monitoringColors() {
    return MONITORING_COLORS;
  }

  static issueStates() {
    return ISSUE_STATES;
  }

  static monitoringDays(mdd) {
    if (mdd === undefined || mdd === null || String(mdd).trim() === '') {
      return 0;
    }
    return dateDiff(mdd, new Date());
  }

  static monitoringColor({ date1, date2, days }) {
    if (date1 === '' || date1 === undefined) {
      if (days === undefined) {
        return '#f9a825';
      }
    }
    if (!date2) {
      date2 = new Date();
    }
    let mdd;

    if (days != undefined) {
      mdd = Number(days);
    } else {
      mdd = dateDiff(date1, date2);
    }

    if (mdd < 0) {
      return MONITORING_COLORS[0];
    } else if (mdd >= 0 && mdd <= 90) {
      return MONITORING_COLORS[1];
    } else {
      return MONITORING_COLORS[2];
    }
  }

  issueState() {
    if (!this.activities) throw new Error('No activities found');
    let issues = sort({
      items: [...this.activities.filter(item => item?.issues || item?.type === 'issues')].map(i => {
        return {
          ...i,
          date: Date.parse(i.date)
        };
      }),
      field: 'date'
    }).map(i => {
      return {
        ...i,
        date: moment(i.date).format('YYYY-MM-DD')
      };
    });

    const latestTransaction = issues[issues.length - 1];
    if (!latestTransaction) {
      throw new Error('No activities found');
    }
    return latestTransaction.issuesResolved
      ? ISSUE_STATES.ISSUE_RESOLVED
      : ISSUE_STATES.ISSUE_EXISTS;
  }

  monitoringDue({ lmd, due } = {}) {
    const lastMonitored = lmd;
    return moment(lastMonitored)
      .add(due || 12, 'months')
      .format('YYYY-MM-DD');
  }

  monitoringStatus({ lmd, due, startupYearEnd } = {}) {
    const dateEnd = startupYearEnd || config.startUpYearEnd;
    const frequency = Number(due || 12)

    const status = {
      late: {
        status: 'late',
        color: MONITORING_COLORS[0]
      },
      now: {
        status: 'now',
        color: MONITORING_COLORS[1]
      },
      done: {
        status: 'done',
        color: MONITORING_COLORS[2]
      },
    }

    let res = {
      status: '',
      color: ''
    }

    if (lmd) {
      const mdd = this.monitoringDue({ lmd, due: frequency })
      const days = Land.monitoringDays(mdd)
      if (days < 0) {
        res = status.late
      } else if (days >= 0 && days <= 90) {
        res = status.now;
      } else {
        res = status.done
      }
    }
    else {
      if (dateEnd) {
        if (moment().isAfter(moment(dateEnd).add(frequency - 12, 'months'))) {
          res = status.late
        }
        else {
          res = status.now
        }
      }
      else {
        res = status.now
      }
    }

    return res;
  }

  async monitoringInfo(organizationId) {
    if (!this.activities || !this.activities.length) throw new Error('No activities found');
    if (!organizationId) console.warn('Organization id is required');

    const res = { lmd: null, mdd: null, monitoringFrequency: 12 };

    const lastMonitored = this.activities.filter(({ type }) => type === 'monitoring');

    if (!lastMonitored.length) {
      throw new Error('No monitoring found');
    }

    lastMonitored.sort(function (a, b) {
      return new Date(b.date).getTime() - new Date(a.date).getTime();
    });

    let landType = '',
      monitoringClass = '';

    const activity = lastMonitored[0];
    res.lmd = activity.date || '';

    let landInformation;
    try {
      landInformation = await this.detail(this.landId);
    } catch (error) {
      console.warn(error);
    }

    landType = landInformation?.type || '';
    monitoringClass = landInformation?.monitoringClass || '';

    let monitoringFreq;
    try {
      const org = new Organization();
      monitoringFreq = await org.getMonitoringFrequency(organizationId);
      if (monitoringFreq && monitoringFreq?.data) {
        const q = `${Land.landType(landType).shortName}_${monitoringClass}`;
        res.monitoringFrequency = monitoringFreq.data[q] || due;
      }
    } catch { }

    if (res.lmd) {
      res.mdd = this.monitoringDue({ lmd: res.lmd, due: res.monitoringFrequency });
    }

    return res;
  }
}

export default Land;
