import { removePadding } from './parcel';
import mapboxgl from 'mapbox-gl';
import Land from '../services/land';
import {
  geostyleHighlight,
  geostyleMonitoring,
  geostyleParcel,
  geostyleParcelSatellite,
  geostyleTct,
  geostyleTot,
  getLandGeostyle
} from './geostyles';

function GeoLocationNotSupported(message) {
  this.message = message;
  return this;
}

GeoLocationNotSupported.prototype = Error.prototype;

const geoCoordinates = {
  latitude: null,
  longitude: null
};

const MAP_STYLE_PREFIX = 'mapbox://styles/mapbox';
const DEFAULT_MAP_STYLE = `${MAP_STYLE_PREFIX}/streets-v11`;

const MAP_STYLES = {
  default: DEFAULT_MAP_STYLE,
  streets: DEFAULT_MAP_STYLE,
  satellite: `${MAP_STYLE_PREFIX}/satellite-v9`,
  light: `${MAP_STYLE_PREFIX}/light-v10`,
  outdoors: `${MAP_STYLE_PREFIX}/outdoors-v11`,
  satelliteStreet: `${MAP_STYLE_PREFIX}/satellite-streets-v11`
};

const DEFAULT_GEOSTYLE = {
  fillColor: '#6A6C6E',
  fillOpacity: 0.5,
  lineColor: '#6A6C6E',
  lineOpacity: 0.5,
  lineWidth: 2,
  circleRadius: 20,
  dash: 'default',
  dashLength: 0,
  gapLength: 0,
  dashArray: '[0, 0]',
  textColor: 'black'
  // fillOpacityHover: 0,
  // fillOpacitySatellite: 0,
  // fillOpacitySatelliteHover: 0
};

const LAYER_GEOMETRY_KEYS = {
  circle: {
    'circle-radius': 'circleRadius',
    'circle-color': 'fillColor',
    'circle-opacity': 'fillOpacity',
    'circle-stroke-color': 'lineColor',
    'circle-stroke-opacity': 'lineOpacity',
    'circle-stroke-width': 'lineWidth'
  },
  line: {
    'line-color': 'lineColor',
    'line-opacity': 'lineOpacity',
    'line-width': 'lineWidth',
    'line-dasharray': 'dasharray'
  },
  fill: {
    'fill-color': 'fillColor',
    'fill-outline-color': 'lineColor',
    'fill-opacity': 'fillOpacity',
    'fill-opacity-hover': 'fillOpacityHover',
    'fill-opacity-satellite': 'fillOpacitySatellite',
    'fill-opacity-satellite-hover': 'fillOpacitySatelliteHover'
  },
  symbol: {
    'text-color': 'textColor'
  }
};

const GEOMETRY_STYLE_KEYS = {
  ...LAYER_GEOMETRY_KEYS.circle,
  ...LAYER_GEOMETRY_KEYS.line,
  ...LAYER_GEOMETRY_KEYS.fill,
  ...LAYER_GEOMETRY_KEYS.symbol
};

/**
 *
 * @param {object} geostyle
 */
const dashArray = geostyle => {
  let dasharray;
  if (geostyle?.dash === 'default') {
    dasharray = [geostyle?.dashLength || 0, geostyle?.gapLength || 0];
    if (dasharray[0] === 0 && dasharray[1] === 0) {
      dasharray = [];
    }
  } else if (geostyle?.dash === 'array') {
    try {
      const da = JSON.parse(geostyle?.dashArray);
      if (Array.isArray(da)) {
        dasharray = da;
      }
    } catch { }
  }
  dasharray = dasharray || [];
  dasharray = dasharray.map(i => Number(i));
  return dasharray;
};

const lngLatFormatter = e => {
  return `${e.lngLat.lat.toFixed(6)}, ${e.lngLat.lng.toFixed(6)}`;
};

const zoomFormatter = e => {
  const zoomLevel = Number(e.getZoom()).toFixed(2);
  return `${zoomLevel}`;
};

const mapInfo = (map, callback) => {
  if (!map) {
    return;
  }

  callback({
    zoom: zoomFormatter(map)
  });

  map.on('pitch', e => {
    const pitch = Number(e.target.getPitch()).toFixed(2);
    callback({ pitch });
  });

  map.on('rotate', e => {
    const bearing = Number(e.target.getBearing()).toFixed(2);
    callback({ bearing });
  });

  map.on('zoomend', e => {
    const zoom = zoomFormatter(e.target);
    callback({ zoom });
  });

  map.on('click', e => {
    callback({ click: lngLatFormatter(e) });
  });

  map.on('mousemove', e => {
    const lngLat = lngLatFormatter(e);
    callback({ lngLat });
  });
};

const getDescription = ({ parcel, acresFilter, referenceFilter, idFilter }) => {
  let res = ``;
  const owner = String(parcel.owner).toLowerCase();
  const { reference, acres, number } = parcel;

  if (owner) {
    if (['tct'].includes(owner)) {
      res += owner.toUpperCase();
    }
  }
  if (reference && referenceFilter) {
    res = `${res}${res ? '\n' : ''}${reference}`;
  }

  if (idFilter) {
    res = `${res}${res ? '\n' : ''}${removePadding(number || '')}`;
  }

  if (acres && acresFilter) {
    res = `${res}${res ? '\n' : ''}${acres} A`;
  }

  return res;
};

const parcelGeoJSON = ({
  parcel,
  acresFilter,
  referenceFilter,
  mapStyle,
  idFilter,
  preserve,
  showPreserve,
  monitoringColor
}) => {
  let highlight = false;
  if (preserve && showPreserve === 'all') {
    if (preserve === parcel?.metrics?.land?.information?.preserve) {
      highlight = true;
    }
  }

  if (!parcel) {
    throw new Error('Parcel is required');
  }

  let geojson = parcel?.geojson || {};

  if (typeof geojson === 'string') {
    geojson = JSON.parse(geojson);
  }

  if (!geojson.properties) {
    geojson.properties = {};
  }

  const landType = Land.parcelLandType(parcel);

  geojson.properties.description =
    getDescription({ parcel, acresFilter, referenceFilter, idFilter }) || '';
  geojson.properties.text = geojson.properties.description;

  geojson.properties.parcelId = parcel.id;
  geojson.properties.owner = String(parcel.owner || '').toUpperCase();

  const geostyle = parcel.geostyle;

  let properties = {};
  //geostyle order: monitoring, highlight, geostyle, land, tct, tot, default

  properties = geostyleToProperties(geostyleParcel);

  if (isSatellite(mapStyle)) {
    properties = { ...properties, ...geostyleToProperties(geostyleParcelSatellite) };
  }

  if (String(parcel.owner).toLowerCase() === 'tct') {
    properties = { ...properties, ...geostyleToProperties(geostyleTct) };
  } else if (String(parcel.owner).toLowerCase() === 'tot') {
    properties = { ...properties, ...geostyleToProperties(geostyleTot) };
  }

  const landGeostyle = getLandGeostyle(landType, mapStyle);
  properties = { ...properties, ...geostyleToProperties(landGeostyle) };

  const geostyleValidated = {};
  if (geostyle) {
    geostyleValidated.fillColor = geostyle?.fillColor;
    geostyleValidated.lineColor = geostyle?.lineColor;
  }
  properties = { ...properties, ...geostyleToProperties(geostyleValidated) };

  if (highlight) {
    properties = { ...properties, ...geostyleToProperties(geostyleHighlight) };
  }

  if (monitoringColor) {
    properties = { ...properties, ...geostyleToProperties(geostyleMonitoring(parcel.mdd)) };
  }

  geojson.properties = { ...geojson.properties, ...properties };

  const id = Math.floor(100000 + Math.random() * 900000);
  geojson.id = id;
  // geojson.id = id;

  return geojson;
};

const isSatellite = mapStyle => {
  if (mapStyle === MAP_STYLES.satellite || mapStyle === MAP_STYLES.satelliteStreet) {
    return true;
  }
  return false;
};

const parcelFillLayer = (source, mapStyle, id) => {
  return {
    id: `${id || ''}${id ? '-' : ''}parcel-fill`,
    type: 'fill',
    source: source,
    filter: ['==', '$type', 'Polygon'],
    layout: {},

    paint: {
      'fill-color': {
        type: 'identity',
        property: 'fill-color'
      },
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        isSatellite(mapStyle)
          ? ['get', 'fill-opacity-satellite-hover']
          : ['get', 'fill-opacity-hover'],
        isSatellite(mapStyle) ? ['get', 'fill-opacity-satellite'] : ['get', 'fill-opacity']
      ]
    }
  };
};

const parcelOutlineLayer = (source, mapStyle, id) => {
  return {
    id: `${id || ''}${id ? '-' : ''}parcel-outline`,
    type: 'line',
    source: source,
    layout: {},
    paint: {
      'line-color': {
        type: 'identity',
        property: 'line-color'
      },
      'line-width': ['get', 'line-width'],
      'line-opacity': ['get', 'line-opacity']
    }
  };
};

const parcelLabelLayer = (source, mapStyle, id) => {
  return {
    id: `${id || ''}${id ? '-' : ''}parcel-label`,
    type: 'symbol',
    source: source,
    paint: {
      'text-color': {
        type: 'identity',
        property: 'text-color'
      }
    },
    layout: {
      'text-field': ['get', 'description'],
      'text-justify': 'center'
    }
  };
};

const getParcelLayers = ({ mapStyle, layers, source, id }) => {
  let res = [
    parcelFillLayer(source, mapStyle, id),
    parcelOutlineLayer(source, mapStyle, id),
    parcelLabelLayer(source, mapStyle, id)
  ];

  if (layers && layers.length) {
    res = res.filter(layer => {
      const index = id ? 2 : 1;
      const layerId = String(layer.id).split('-')[index];
      return layers.includes(layerId);
    });
  }

  return res;
};

const addParcelLayer = ({ map, source, id, hidden }) => {
  const visibility = hidden ? 'none' : 'visible';
  const mapStyle = map.getStyle().sprite;
  let parcelLayers = getParcelLayers({ mapStyle, source, id });

  parcelLayers = parcelLayers.map(layer => {
    return {
      ...layer,
      layout: {
        ...layer.layout,
        visibility
      }
    };
  });

  parcelLayers.forEach(layer => {
    map.addLayer(layer);
  });
};

const addIdToLayer = (id, layer) => {
  return `${id || ''}${id ? '-' : ''}${layer}`;
};

const parcelLayers = id => {
  let layers = ['parcel', 'parcel-fill', 'parcel-outline', 'parcel-label'];
  layers = layers.map(layer => addIdToLayer(id, layer));
  return layers;
};

const showParcelLayer = (map, id, value) => {
  const layers = parcelLayers(id);
  const visibility = value ? 'visible' : 'none';
  layers.forEach(layer => {
    if (map.getLayer(layer)) {
      map.setLayoutProperty(layer, 'visibility', visibility);
    }
  });
};

const hideParcelLayer = (map, id, value) => {
  const layers = parcelLayers(id);
  const visibility = value ? 'visible' : 'none';
  layers.forEach(layer => {
    if (map.getLayer(layer)) {
      map.setLayoutProperty(layer, 'visibility', visibility);
    }
  });
};

const toggleParcelLayers = (map, id, value) => {
  const layers = parcelLayers(id);
  const visibility = value ? 'visible' : 'none';

  layers.forEach(layer => {
    if (map.getLayer(layer)) {
      map.setLayoutProperty(layer, 'visibility', visibility);
    }
  });
};

const addHover = ({ map, id, source }) => {
  let hoverId = null;
  map.on('mousemove', id, function (e) {
    if (e.features.length > 0) {
      if (hoverId !== null) {
        map.setFeatureState(
          {
            source: source,
            id: hoverId
          },
          {
            hover: false
          }
        );
      }
      hoverId = e.features[0].id;
      map.setFeatureState(
        {
          source: source,
          id: hoverId
        },
        {
          hover: true
        }
      );
    }
  });

  map.on('mouseleave', id, function () {
    if (hoverId !== null) {
      map.setFeatureState(
        {
          source: source,
          id: hoverId
        },
        {
          hover: false
        }
      );
    }
    hoverId = null;
  });
};

const removeLayer = (map, layer) => {
  if (map.getLayer(layer)) {
    map.removeLayer(layer);
  }
};
const removeSource = (map, source) => {
  if (map.getSource(source)) {
    map.removeSource(source);
  }
};

const circleLayer = ({ source, id, radius, color, hidden }) => {
  return {
    id,
    type: 'circle',
    source,
    layout: {
      visibility: hidden ? 'none' : 'visible'
    },
    paint: {
      'circle-radius': radius || 10,
      'circle-color': color || '#ff5252'
    }
  };
};

const teamsFromGeoJSON = (geojson, featureTypes = ['Polygon']) => {
  if (!geojson || typeof geojson !== 'object') {
    return;
  }
  const teams = [];
  geojson.features.forEach(feature => {
    const featureType = feature?.geometry?.type;
    const team = feature.properties.Team;
    if (featureTypes.includes(featureType)) {
      teams.push(team);
    }
  });
  return teams;
};

const teamOutlineLayer = source => {
  return {
    id: `${source}-outline`,
    type: 'line',
    source: source,
    layout: {},
    paint: {
      'line-color': {
        type: 'identity',
        property: 'stroke'
      },
      'line-width': 1.6
    }
  };
};

const teamFillLayer = source => {
  return {
    id: `${source}-fill`,
    type: 'fill',
    source: source,
    filter: ['==', '$type', 'Polygon'],
    layout: {},

    paint: {
      'fill-color': {
        type: 'identity',
        property: 'fill'
      },
      'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0.1]
    }
  };
};

const teamLabelLayer = source => {
  return {
    id: `${source}-label`,
    type: 'symbol',
    source: source,
    paint: {
      'text-color': {
        type: 'identity',
        property: 'fill'
      }
    },
    layout: {
      'text-field': ['get', 'Team'],
      'text-justify': 'center'
    }
  };
};

const boundaryLayer = ({ visibility, color }) => {
  return {
    id: 'boundary',
    type: 'line',
    source: 'boundary',
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
      visibility: visibility ? 'visible' : 'none'
    },
    paint: {
      'line-dasharray': [3, 3],
      'line-color': color || 'rgba(0, 0, 0, 0.6)',
      'line-width': 1
    }
  };
};

const addTeamLayer = ({ map, source, visibility }) => {
  let teamLayers = [teamOutlineLayer(source), teamFillLayer(source), teamLabelLayer(source)];

  teamLayers = teamLayers.map(layer => {
    layer.layout.visibility = visibility ? 'visible' : 'none';
    return layer;
  });

  teamLayers.forEach(layer => {
    map.addLayer(layer);
  });

  const layer = teamLayers[1].id;
  addHover({ map, id: layer, source });

  toggleLayerOnZoom({
    map,
    layers: layer,
    depends: teamLayers[0].id,
    cmp: zoom => {
      return zoom <= 13;
    }
  });
};

const teamLayers = source => {
  let layers = ['label', 'fill', 'outline'];
  layers = layers.map(layer => {
    return `${source}-${layer}`;
  });
  return layers;
};

const addTeam = ({ map, geojson, boundary, teamLayer, onClick }) => {
  if (!map || !geojson || typeof geojson !== 'object') {
    console.warn(`${map ? 'map' : 'geojson'} required`);
    return;
  }

  const teamPolygon = [];

  geojson.features.forEach(feature => {
    const data = { ...feature };
    const id = Math.floor(100000 + Math.random() * 900000);
    data.id = id;

    const type = data?.geometry?.type;
    if (type === 'Polygon') {
      teamPolygon.push(data);
    }
  });

  teamPolygon.forEach(feature => {
    const { Team } = feature.properties;
    const source = `team-${Team}`;
    if (!map.getSource(source)) {
      map.addSource(source, { type: 'geojson', data: feature });
      addTeamLayer({ map, source, visibility: teamLayer });

      map.on('click', `${source}-fill`, () => {
        onClick(Team);
      });
    }
  });

  addBoundary(map, geojson, boundary);
};

const addBoundary = (map, geojson, boundary) => {
  removeLayer(map, 'boundary');
  removeSource(map, 'boundary');
  if (!map || !geojson || typeof geojson !== 'object') {
    console.warn(`${map ? 'map' : 'geojson'} required`);
    return;
  }

  const teamLineString = [];

  geojson.features.forEach(feature => {
    const data = { ...feature };
    const id = Math.floor(100000 + Math.random() * 900000);
    data.id = id;
    const type = data?.geometry?.type;
    if (type === 'LineString') {
      teamLineString.push(data);
    }
  });

  map.addSource('boundary', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: teamLineString
    }
  });
  const color = String(map.getStyle().sprite).includes('satellite') ? 'white' : '';
  map.addLayer(boundaryLayer({ visibility: boundary, color }));
};

/**
 ** @param {
 **   map: mapbox object
 **   layers: array<string> or string
 **   cmp: function(zoom){
 **    if true layer will be visible
 **   }
 ** }
 */

const toggleLayerOnZoom = ({ map, layers, depends, cmp }) => {
  let l = layers;
  if (typeof l === 'string') {
    l = [l];
  }
  map.on('moveend', () => {
    const zoomLevel = map.getZoom();

    if (depends) {
      const dLayer = map.getLayer(depends);
      if (dLayer?.visibility === 'none') {
        return;
      }
    }
    l.forEach(layer => {
      if (map.getLayer(layer)) {
        const visibility = cmp(zoomLevel) ? 'visible' : 'none';
        map.setLayoutProperty(layer, 'visibility', visibility);
      }
    });
  });
};

const toggleLayer = (map, layers, value) => {
  let ids = layers;
  if (typeof ids === 'string') {
    ids = [ids];
  }
  ids.forEach(layerId => {
    if (map.getLayer(layerId)) {
      map.setLayoutProperty(layerId, 'visibility', value ? 'visible' : 'none');
    }
  });
};

/**
 *
 * @param {FeatureCollection<Polygon>} geojson
 * @param {Center<lng, lat>} center
 * @returns {String} team
 */

const parcelTeam = (geojson, center) => {
  const { point, booleanPointInPolygon } = require('@turf/turf');
  if (!geojson || geojson.type !== 'FeatureCollection') {
    throw new Error('geojson must be FeatureCollection object');
  }
  if (!center) {
    throw new Error('center is required');
  }
  const pt = point(center);
  const teams = geojson.features.filter(ft => {
    return booleanPointInPolygon(pt, ft);
  });
  if (!teams || !teams.length) {
    return '';
  }
  return teams[0].properties.Team;
};

/**
 *
 * @returns {Promise<{latitude, longitude}>}
 */
const getCoordinates = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new GeoLocationNotSupported('Your browser does not support HTML5 geolocation.'));
    }
    const res = {
      latitude: null,
      longitude: null
    };

    navigator.geolocation.getCurrentPosition(
      pos => {
        res.latitude = pos.coords.latitude;
        res.longitude = pos.coords.longitude;
        resolve(res);
      },
      error => {
        reject(error);
      }
    );
  });
};

const navigationControl = () => {
  return new mapboxgl.NavigationControl();
};

const fullscreenControl = () => {
  return new mapboxgl.FullscreenControl();
};

const geolocateControl = () => {
  return new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true
    },
    trackUserLocation: true,
    showUserHeading: true
  });
};

/**
 *
 * @param {mapboxgl.Map} map
 * @param {Array<mapboxgl.Control>} controls
 * @param {string} position
 *
 */

const addControls = async ({ map, controls, position }) => {
  const pos = position || 'top-right';
  const ctrls = controls.map(c => {
    if (c === 'NavigationControl') {
      return navigationControl();
    } else if (c === 'GeolocateControl') {
      return geolocateControl();
    } else if (c === 'FullScreenControl') {
      return fullscreenControl();
    } else {
      throw new Error('Invalid control ', c);
    }
  });
  ctrls.forEach(control => {
    map.addControl(control, pos);
  });
};

const cmsLayers = (source, geostyle, labelStyles) => {
  const defaultLabelStyles = () => {
    return {
      color: '#000',
      text: '',
      opacity: 1,
      size: 14,
      font: '["Open Sans Regular","Arial Unicode MS Regular"]',
      offsetX: 0,
      offSetY: 0,
      anchor: 'center',
      justify: 'center',
      placement: 'point'
    };
  };

  const fillColor = '#6A6C6E';
  const fillOpacity = 0.5;
  const lineColor = '#6A6C6E';
  const lineOpacity = 0.5;
  const lineWidth = 2;
  const circleRadius = 20;

  const fill = {
    id: `${source}-fill`,
    type: 'fill',
    source,
    paint: {
      'fill-color': geostyle?.fillColor || fillColor,
      'fill-outline-color': geostyle?.lineColor || lineColor,
      'fill-opacity': Number(geostyle?.fillOpacity) || fillOpacity
    },
    filter: ['==', '$type', 'Polygon']
  };

  const outline = {
    id: `${source}-outline`,
    type: 'line',
    source,
    paint: {
      'line-color': geostyle?.lineColor || lineColor,
      'line-opacity': Number(geostyle?.lineOpacity) || lineOpacity,
      'line-width': Number(geostyle?.lineWidth) || lineWidth
    },
    filter: ['all', ['==', '$type', 'Polygon']]
  };

  const dasharray = dashArray(geostyle);

  const line = {
    id: `${source}-line`,
    type: 'line',
    source,
    paint: {
      'line-color': geostyle?.lineColor || lineColor,
      'line-width': Number(geostyle?.lineWidth) || lineWidth,
      'line-opacity': Number(geostyle?.lineOpacity) || lineOpacity,
      'line-dasharray': dasharray || []
    },
    filter: ['all', ['==', '$type', 'LineString']]
  };

  const circle = {
    id: `${source}-circle`,
    type: 'circle',
    source,
    paint: {
      'circle-radius': Number(geostyle?.circleRadius) || ['get', 'circle-radius'] || circleRadius,
      'circle-color': geostyle?.fillColor || fillColor,
      'circle-opacity': Number(geostyle?.fillOpacity) || fillOpacity,
      'circle-stroke-color': geostyle?.lineColor || lineColor,
      'circle-stroke-opacity': Number(geostyle?.lineOpacity) || lineOpacity,
      'circle-stroke-width': Number(geostyle?.lineWidth) || lineWidth
    },
    filter: ['any', ['==', '$type', 'Point']]
  };

  const symbolConfig = { ...defaultLabelStyles(), ...labelStyles };

  let font;
  try {
    font = JSON.parse(symbolConfig.font);
  } catch { }

  const symbol = {
    id: `${source}-symbol`,
    type: 'symbol',
    source: source,
    paint: {
      'text-color': symbolConfig.color,
      'text-opacity': symbolConfig.opacity
    },
    layout: {
      'symbol-placement': symbolConfig.placement,
      'text-field': ['get', 'text'],
      'text-size': symbolConfig.size,
      'text-font': font || [],
      'text-offset': [symbolConfig.offsetX, symbolConfig.offSetY],
      'text-anchor': symbolConfig.anchor,
      'text-justify': symbolConfig.justify
    }
  };

  return [fill, outline, circle, line, symbol];
};

const units = [
  { text: 'Kilometers', value: 'km', unit: 'kilometers' },
  { text: 'Pixels', value: 'px', unit: 'pixels' },
  { text: 'Miles', value: 'mi', unit: 'miles' }
];

/**
 *
 * @param {center, units, radius, steps, properties} geocircle
 * @returns {GeoJSON}
 */
const geocircleToGeojson = geocircle => {
  const { circle } = require('@turf/turf');
  const { center, radius, steps, properties } = geocircle || {};
  const data = { center, radius, steps, properties };

  if (!center) {
    return;
  }

  const unit = units.filter(u => u.value === geocircle.units)[0];

  data.center = JSON.parse(data.center);
  data.radius = Number(data.radius);
  data.steps = Number(data.steps);

  try {
    data.properties = JSON.parse(properties);
  } catch { }

  if (unit.unit === 'pixels') {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: data.center
      },
      properties: {
        ...data.properties,
        'circle-radius': data.radius
      }
    };
  }

  const options = {
    steps: data.steps,
    units: unit.unit,
    properties: {
      ...data.properties
    }
  };
  return circle(data.center, data.radius, options);
};

const cmsLayerIds = source => {
  return ['circle', 'outline', 'line', 'fill', 'symbol'].map(l => `${source}-${l}`);
};

const parseGeoPoint = data => {
  let coordinates;
  try {
    if (typeof data.coordinates === 'string') {
      coordinates = JSON.parse(data.coordinates) || [];
    } else if (Array.isArray(data.coordinates)) {
      coordinates = data.coordinates;
    } else {
      data.coordinates = [];
    }
  } catch (error) {
    coordinates = [];
  }

  if (data.pointType === 'point') {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates
      },
      properties: {
        ...(data.properties || {})
      }
    };
  } else if (data.pointType === 'marker') {
    return {
      coordinates,
      scale: Number(data.scale),
      rotation: Number(data.rotation),
      color: data.color
    };
  }
  return null;
};

const parseGeoJSON = data => {
  if (typeof data === 'object') {
    return data;
  }
  try {
    return JSON.parse(data);
  } catch {
    return null;
  }
};

const geojsonCenter = data => {
  const { center } = require('@turf/turf');
  if (Array.isArray(data) && data.length === 2) {
    return data;
  }
  const ctr = center(data);
  return ctr.geometry.coordinates;
};

const distanceBetweenPoints = (pointA, pointB, unit = 'mi', decimal = 2) => {
  const { distance, point } = require('@turf/turf');
  const centerA = geojsonCenter(pointA);
  const centerB = geojsonCenter(pointB);
  let result = distance(point(centerA), point(centerB), { unit: 'miles' });

  if (unit === 'km') {
    result = result * 1.60934;
  } else if (unit === 'yd') {
    result = result * 1760;
  }

  return parseFloat(result.toFixed(decimal));
};

const layerFill = source => {
  return {
    id: `${source}-fill`,
    type: 'fill',
    source,
    paint: {
      'fill-color': DEFAULT_GEOSTYLE.fillColor,
      'fill-outline-color': DEFAULT_GEOSTYLE.lineColor,
      'fill-opacity': DEFAULT_GEOSTYLE.fillOpacity
    },
    filter: ['==', '$type', 'Polygon']
  };
};

const layerOutlilne = source => {
  return {
    id: `${source}-outline`,
    type: 'line',
    source,
    paint: {
      'line-color': DEFAULT_GEOSTYLE.lineColor,
      'line-opacity': DEFAULT_GEOSTYLE.lineOpacity,
      'line-width': DEFAULT_GEOSTYLE.lineWidth
    },
    filter: ['all', ['==', '$type', 'Polygon'], ['==', '$type', 'LineString']]
  };
};

const layerCircle = source => {
  return {
    id: `${source}-circle`,
    type: 'circle',
    source,
    paint: {
      'circle-radius': DEFAULT_GEOSTYLE.circleRadius,
      'circle-color': DEFAULT_GEOSTYLE.fillColor,
      'circle-opacity': DEFAULT_GEOSTYLE.fillOpacity,
      'circle-stroke-color': DEFAULT_GEOSTYLE.lineColor,
      'circle-stroke-opacity': DEFAULT_GEOSTYLE.lineOpacity,
      'circle-stroke-width': DEFAULT_GEOSTYLE.lineWidth
    },
    filter: ['any', ['==', '$type', 'Point']]
  };
};

const layerSymbol = source => {
  return {
    id: `${source}-symbol`,
    type: 'symbol',
    source: source,
    paint: {
      'text-color': DEFAULT_GEOSTYLE.textColor
    },
    layout: {
      'text-field': ['get', 'text'],
      'text-justify': 'center'
    }
  };
};

const mapLayers = (source, layers = ['fill', 'outline', 'circle', 'symbol']) => {
  const ls = {
    fill: layerFill(source),
    outline: layerOutlilne(source),
    circle: layerCircle(source),
    symbol: layerSymbol(source)
  };
  const results = [];
  Object.keys(ls).forEach(key => {
    if (layers.includes(key)) {
      results.push(ls[key]);
    }
  });
  return results;
};

const validateGeostyle = geostyle => {
  if (!geostyle) {
    return DEFAULT_GEOSTYLE;
  }
  const numberFields = ['fillOpacity', 'lineOpacity', 'lineWidth', 'circleRadius'];
  const res = { ...DEFAULT_GEOSTYLE, ...geostyle };

  res.dasharray = dashArray(res);

  Object.keys(res).forEach(key => {
    if (numberFields.includes(key)) {
      res[key] = Number(res[key]);
    }
  });

  return res;
};

const updatePaint = (paint, geostyle, values) => {
  const gs = validateGeostyle(geostyle);

  let res = paint;

  const keys = Object.keys(values || {}).filter(k => {
    return Object.keys(geostyle || {}).includes(values[k]);
  });

  keys.forEach(key => {
    if (gs[values[key]] && gs[values[key]] !== 0) {
      res[key] = gs[values[key]];
    }
  });

  return res;
};

const applyGeostyleOnLayer = (layer, geostyle) => {
  const values = LAYER_GEOMETRY_KEYS;

  const type = Array.isArray(layer) ? 'array' : 'object';

  let layers = [];

  if (type === 'array') {
    layers.push(...layer);
  } else {
    layers.push(layer);
  }

  layers = layers.map(l => {
    const validatedData = l;
    let style;

    Object.keys(values).forEach(layerType => {
      if (layerType === l.type) {
        style = values[layerType];
      }
    });

    validatedData.paint = updatePaint(l.paint, geostyle, style);
    return validatedData;
  });

  if (type === 'object') {
    return layers[0];
  }

  return layers;
};

const geostyleToProperties = geostyle => {
  const gs = validateGeostyle(geostyle);
  const properties = {};
  Object.keys(GEOMETRY_STYLE_KEYS).forEach(key => {
    const value = (geostyle || {})[GEOMETRY_STYLE_KEYS[key]];
    if (value || value === 0 || value === '0') {
      properties[key] = gs[GEOMETRY_STYLE_KEYS[key]];
    }
  });
  return properties;
};

const applyGeostyleOnFeatures = (features, geostyle) => {
  let res = [];
  const array = Array.isArray(features) ? true : false;

  if (array) {
    res.push(...features);
  } else {
    res.push(features);
  }

  res = res.map(item => {
    const feature = item;
    feature.properties = { ...feature.properties, ...geostyleToProperties(geostyle) };
    return feature;
  });

  if (!array) {
    return res[0];
  }
  return res;
};

const labelToGeostyle = label => {
  if (!label) {
    label = {};
  }

  let offset = '[0,0]';
  if (label.offsetX && label.offSetY) {
    offset = JSON.stringify([label.offsetX, label.offSetY]);
  }

  const res = {
    symbolPlacement: label?.placement || 'point',
    textColor: label?.color || 'black',
    textFont: label?.font || '["Open Sans Regular","Arial Unicode MS Regular"]',
    textOpacity: 1,
    textSize: label.size || 16,
    textOffset: offset,
    textAnchor: label?.anchor || 'center',
    textJustify: label?.justify || 'center'
  };
  return res;
};

export {
  mapInfo,
  lngLatFormatter,
  parcelGeoJSON,
  MAP_STYLES,
  DEFAULT_MAP_STYLE,
  getDescription,
  addParcelLayer,
  addHover,
  removeLayer,
  removeSource,
  showParcelLayer,
  hideParcelLayer,
  parcelLayers,
  circleLayer,
  teamOutlineLayer,
  addTeam,
  toggleLayerOnZoom,
  teamsFromGeoJSON,
  toggleLayer,
  toggleParcelLayers,
  teamLayers,
  addBoundary,
  parcelTeam,
  getCoordinates,
  addControls,
  cmsLayers,
  isSatellite,
  units,
  geocircleToGeojson,
  cmsLayerIds,
  parseGeoPoint,
  parseGeoJSON,
  geojsonCenter,
  distanceBetweenPoints,
  mapLayers,
  validateGeostyle,
  dashArray,
  DEFAULT_GEOSTYLE,
  applyGeostyleOnLayer,
  applyGeostyleOnFeatures,
  geostyleToProperties,
  zoomFormatter,
  labelToGeostyle
};
