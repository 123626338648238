const parseTimestamp = ts => {
  let date = null;
  if (ts && typeof ts.getMonth === 'function') {
    date = new Date(ts);
  } else if (typeof ts === 'object') {
    const seconds = ts.seconds || ts._seconds;
    date = new Date(seconds * 1000);
  } else {
    date = new Date(ts);
  }
  if (date == 'Invalid Date') {
    return '';
  }
  return date;
};

export { parseTimestamp };
