import { getFirestore } from './firebase';
import { removePadding, getStreetAddress } from '../utils/parcel';
import { galleryRef } from './firestore';
import Drive from '../components/Drive/drive';

import config from '../config';

import Land from './land';
import { CMS } from './cms';

import Moment from 'moment';

import Organization from './organization';
import { downloadJSON } from '../utils';

const org = new Organization();
const TYPES = [
  { value: 'resPrimary', text: 'Primary Residence' },
  { text: 'Part-Time Residence', value: 'resPartTime' },
  { text: 'Rental Property', value: 'rentalProperty' },
  { text: 'Buildable Lot', value: 'lotBuildable' },
  { text: 'Wetland', value: 'lotWetland' },
  { value: 'openSpace', text: 'Open Space' },
  { value: 'commercial', text: 'Commercial' },
  { text: 'Other', value: 'other' }
];

class Parcel {
  constructor({ id, parcel } = {}) {
    this.ref = getFirestore().collection(config.firestore.parcel);
    this.TYPES = TYPES;

    this.fields = {
      parcelNumber: 'number'
    };

    this.id = id;
    this.parcel = parcel;
    this.snapshot = null;
  }

  setId(id) {
    this.id = id;
  }

  list({ id } = {}) {
    return new Promise((resolve, reject) => {
      this.ref
        .where('user', '==', id)
        .get()
        .then(res => {
          const parcels = [];
          res.docs.forEach(p => {
            parcels.push({ id: p.id, ...p.data() });
          });
          resolve(parcels);
        })
        .catch(err => reject(err));
    });
  }

  fetch() {
    if (!this.id) {
      throw new Error('Parcel id is required');
    }
    return new Promise((resolve, reject) => {
      this.ref
        .doc(this.id)
        .get()
        .then(res => {
          if (res.exists) {
            const data = { ...res.data() };
            this.parcel = data;
            resolve(data);
          } else {
            reject({ message: 'Parcel does not exists' });
          }
        })
        .catch(reject);
    });
  }

  unsubscribe() {
    if (this.snapshot) {
      this.snapshot();
      this.snapshot = null;
    }
  }

  onSnapshot(callback) {
    if (!this.id) {
      throw new Error('Parcel id is required');
    }
    this.snapshot = this.ref.doc(this.id).onSnapshot(res => {
      if (res.exists) {
        const data = { ...res.data() };
        this.parcel = data;
        callback(data);
      } else {
        this.unsubscribe();
      }
    });
  }

  get() {
    return this.parcel;
  }

  getRef() {
    return this.ref;
  }

  async detail() {
    await this.fetch(this.id);
    return this.get();
  }

  delete() {
    return this.ref.doc(this.id).delete();
  }

  async getNumber() {
    await this.fetch();
    if (this.parcel) {
      return this.parcel[this.fields.parcelNumber];
    } else {
      return null;
    }
  }

  type(value) {
    return this.TYPES.filter(t => t.value === value)[0]?.text;
  }

  update(newData) {
    if (!this.parcel) {
      throw new Error('Parcel data is not fetched');
    }
    const data = { ...this.parcel, ...(newData || {}), updatedAt: new Date() };
    delete data.id;
    return this.ref.doc(this.id).set(data);
  }

  monitoringDue({ lmd, due } = {}) {
    const lastMonitored = lmd;
    return Moment(lastMonitored)
      .add(due || 12, 'months')
      .format('YYYY-MM-DD');
  }

  async updateLastMonitoredDate() {
    await this.fetch();
    const land = new Land();

    const lastMonitored = await land.listActivity(this.id, 'monitoring');

    if (lastMonitored.length) {
      lastMonitored.sort(function (a, b) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    }

    let lmd = '',
      mdd = '',
      landType = '',
      monitoringClass = '',
      due = '12';

    const activity = lastMonitored[0];
    lmd = activity?.date || '';
    let landInformation;

    try {
      landInformation = await land.detail(this.id);
    } catch (error) {
      console.warn(error);
    }

    landType = landInformation?.type || '';
    monitoringClass = landInformation?.monitoringClass || '';

    due = '12';

    let monitoringFreq;
    try {
      monitoringFreq = await org.getMonitoringFrequency(this.parcel.user);
      if (monitoringFreq && monitoringFreq.data) {
        const q = `${Land.landType(landType).shortName}_${monitoringClass}`;
        due = monitoringFreq.data[q] || due;
      }
    } catch { }

    if (lmd) {
      mdd = this.monitoringDue({ lmd, due });
    }

    this.parcel = { ...this.parcel, lmd, mdd, monitoringFrequency: due, mddUpdated: true };
    await this.update();
    return this.parcel;
  }

  checkParcelFetched() {
    if (!this.parcel) {
      throw new Error('parcel is not fetched');
    }
  }

  removePadding(addExtraZero) {
    this.checkParcelFetched();
    return removePadding(this.parcel.number, addExtraZero);
  }

  getStreetAddress() {
    this.checkParcelFetched();
    return getStreetAddress(this.parcel);
  }

  getLandType() {
    this.checkParcelFetched();
    return this.parcel?.metrics?.land?.information?.type || '';
  }

  async updateGalleryCount() {
    await this.fetch();
    const data = { ...this.parcel, updatedAt: new Date() };
    const galleryQ = await galleryRef
      .where('parcelId', '==', this.parcel.number)
      .where('type', '==', 'Parcel')
      .get();
    data.galleryCount = galleryQ.size;
    await this.update(data);
  }

  async updateDriveCount() {
    await this.fetch();
    const data = { ...this.parcel, updatedAt: new Date() };

    const drive = new Drive();
    const files = await drive.listFiles({ folderId: this.id });
    const folders = await drive.listFolders({ parentId: this.id });
    const count = files.size + folders.size;
    data.driveCount = count || 0;
    await this.update(data);
  }

  parcelGeoJSON() {
    if (!this.parcel) {
      throw new Error('Error exporting parcel geojson');
    }

    let geojson = JSON.parse(this.parcel.geojson);
    if (!this.parcel.geojson || !geojson) {
      throw new Error('Invalid geojson');
    }
    if (!geojson.properties) {
      geojson.properties = {};
    }

    const parcelProperties = Object.keys(this.parcel).filter(
      key =>
        ![
          'geojson',
          'center',
          'centroid',
          'bbox',
          'recent_activity',
          'timestampGeoUpdated',
          'features',
          'metrics'
        ].includes(key)
    );

    parcelProperties.forEach(key => {
      geojson.properties[key] = this.parcel[key];
    });

    if (geojson.type === 'Feature') {
      geojson = {
        type: 'FeatureCollection',
        features: [geojson]
      };
    }

    return geojson;
  }

  exportGeojson() {
    const geojson = this.parcelGeoJSON();
    downloadJSON(geojson, `Export-${this.parcel.number}`);
  }

  saveGeoJSONToWidget(key, user) {
    return new Promise((resolve, reject) => {
      const cms = new CMS();
      const geojson = this.parcelGeoJSON();

      const data = {
        geojson: JSON.stringify(geojson, null, '\t'),
        user
      };
      cms
        .create({ type: 'geojson', key, data })
        .then(res => {
          resolve(res);
        })
        .catch(error => {
          reject(String(error));
        });
    });
  }

  setFeatureCount(feature, count) {
    if ([null, undefined].includes(count)) return;
    if (!this.parcel.metrics) {
      this.parcel.metrics = {};
    }
    if (!this.parcel.metrics[feature]) {
      this.parcel.metrics[feature] = {};
    }
    this.parcel.metrics[feature].count = count;
  }
}

export default Parcel;
